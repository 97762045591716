import React from "react";
import BounceLoader from "../bounce-loader";

const Loader = ({ className = "", title }) => {
  return (
    <div className={`flex flex-col justify-center items-center ${className}`}>
      <BounceLoader />
      {title && <h3 className="mt-4">{title}</h3>}
    </div>
  );
};

export default Loader;

import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  fetchCompetitionsLoading: false,
  fetchLiveScoreLoading: false,
  fetchStandingsLoading: false,
  fetchHistoriesLoading: false,
  competitions: null,
  liveScores: null,
  standings: null,
  histories: null,
  historiesPage: 1,
  historiesTotal: 0,
  historiesLoadMore: false,
  fetchHistoriesError: null,
  fetchLiveScoreError: null,
  fetchStandingsError: null,
  fetchCompetitionsError: null,
};

const competitionSlice = createSlice({
  name: "competition",
  initialState: INITIAL_STATE,
  reducers: {
    competitionsFetchStart: (state) => {
      return {
        ...state,
        fetchCompetitionsLoading: true,
        fetchCompetitionsError: null,
      };
    },
    competitionsFetchSuccess: (state, { payload: { competitions } }) => {
      return {
        ...state,
        fetchCompetitionsLoading: false,
        competitions,
      };
    },
    competitionsFetchError: (state, { payload: { error } }) => {
      return {
        ...state,
        fetchCompetitionsLoading: false,
        fetchCompetitionsError: error,
      };
    },
    liveScoresFetchStart: (state) => {
      return {
        ...state,
        fetchLiveScoreLoading: true,
        fetchLiveScoreError: null,
      };
    },
    liveScoresFetchSuccess: (state, { payload: { liveScores } }) => {
      return {
        ...state,
        fetchLiveScoreLoading: false,
        liveScores,
      };
    },
    liveScoresFetchError: (state, { payload: { error } }) => {
      return {
        ...state,
        fetchLiveScoreLoading: false,
        fetchLiveScoreError: error,
      };
    },
    standingsFetchStart: (state) => {
      return {
        ...state,
        fetchStandingsLoading: true,
        fetchStandingsError: null,
      };
    },
    standingsFetchSuccess: (state, { payload: { standings } }) => {
      return {
        ...state,
        fetchStandingsLoading: false,
        standings,
      };
    },
    standingsFetchError: (state, { payload: { error } }) => {
      return {
        ...state,
        fetchStandingsLoading: false,
        fetchStandingsError: error,
      };
    },
    historiesFetchStart: (state, { payload: { loadMore = false } }) => {
      return {
        ...state,
        fetchHistoriesLoading: true,
        fetchHistoriesError: null,
        historiesLoadMore: loadMore,
      };
    },
    historiesFetchSuccess: (
      state,
      { payload: { histories, page, total, loadMore = false } }
    ) => {
      return {
        ...state,
        fetchHistoriesLoading: false,
        histories: loadMore ? [...state.histories, ...histories] : histories,
        historiesPage: page,
        historiesTotal: total,
        historiesLoadMore: false,
      };
    },
    historiesFetchError: (state, { payload: { error, loadMore = false } }) => {
      return {
        ...state,
        fetchHistoriesLoading: false,
        fetchHistoriesError: loadMore ? null : error,
        historiesLoadMore: false,
      };
    },
  },
});

export const {
  competitionsFetchStart,
  competitionsFetchSuccess,
  competitionsFetchError,
  liveScoresFetchStart,
  liveScoresFetchSuccess,
  liveScoresFetchError,
  standingsFetchStart,
  standingsFetchSuccess,
  standingsFetchError,
  historiesFetchStart,
  historiesFetchSuccess,
  historiesFetchError,
} = competitionSlice.actions;

export default competitionSlice.reducer;

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import OnBoardingPage from "./pages/on-boarding";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import CompetitionPage from "./pages/competition";
import QuizPage from "./pages/quiz";
import ProfilePage from "./pages/profile";
import SplashPage from "./pages/splash";
import ProfileSetupPage from "./pages/profile-setup";
import QuizStartPage from "./pages/quiz-start";
import LeaderBoardPage from "./pages/leader-board";
import FeedPage from "./pages/feed";
import FeedDetailPage from "./pages/feed-detail";
import ProtectedRoute from "./components/protected-route";
import Header from "./components/header";
import BottomTab from "./components/bottom-tab";

import { API_BASE_URL } from "./utils/constants";
import {
  sessionRehydrateStart,
  profileFetchStart,
  logOut,
} from "./store/auth/reducer";

const App = () => {
  axios.defaults.baseURL = API_BASE_URL;
  const dispatch = useDispatch();
  const { token, user, sessionHydrated } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(sessionRehydrateStart());
    const interceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          dispatch(logOut());
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    if (token && !user) {
      dispatch(profileFetchStart());
    }
  }, [token]);
  return (
    <div className="w-screen h-screen bg-green-100 flex justify-center">
      <div className="max-w-sm bg-white flex flex-col w-full overflow-y-scroll">
        {user && user.isProfileCompleted && <Header />}
        <div className="flex-grow">
          {!sessionHydrated || (token && !user) ? (
            <SplashPage />
          ) : user && !user.isProfileCompleted ? (
            <ProfileSetupPage />
          ) : (
            <Routes>
              <Route
                path="/"
                element={<>{token ? <HomePage /> : <OnBoardingPage />}</>}
              />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/competitions/:id"
                element={
                  <ProtectedRoute>
                    <CompetitionPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/feeds"
                element={
                  <ProtectedRoute>
                    <FeedPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/feeds/:id"
                element={
                  <ProtectedRoute>
                    <FeedDetailPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/quizzes"
                element={
                  <ProtectedRoute>
                    <QuizPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/quizzes/:id"
                element={
                  <ProtectedRoute>
                    <QuizStartPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/leader-board"
                element={
                  <ProtectedRoute>
                    <LeaderBoardPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          )}
        </div>
        {user && user.isProfileCompleted && <BottomTab />}
      </div>
    </div>
  );
};

export default App;

export const DATA = [
  {
    label: "Today",
    value: "TODAY",
  },
  {
    label: "This Week",
    value: "THIS_WEEK",
  },
  {
    label: "This Month",
    value: "THIS_MONTH",
  },
  {
    label: "All Time",
    value: "ALL_TIME",
  },
];

import React from "react";

const BounceLoader = ({ className = "", color = "bg-primary" }) => {
  return (
    <div className={`flex w-max ${className}`}>
      <div
        className={`h-2.5 w-2.5 ${color} rounded-full mr-1 animate-bounce`}
      ></div>
      <div
        className={`h-2.5 w-2.5 ${color} rounded-full mr-1 animate-bounce-200`}
      ></div>
      <div
        className={`h-2.5 w-2.5 ${color} rounded-full animate-bounce-400`}
      ></div>
    </div>
  );
};

export default BounceLoader;

import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/reducer";
import competitionReducer from "./competition/reducer";
import categoryReducer from "./category/reducer";
import quizReducer from "./quiz/reducer";
import leaderReducer from "./leader/reducer";
import feedReducer from "./feed/reducer";

const rootReducer = combineReducers({
  competition: competitionReducer,
  auth: authReducer,
  category: categoryReducer,
  quiz: quizReducer,
  leader: leaderReducer,
  feed: feedReducer,
});

export default rootReducer;

import React from "react";
import { RefreshIcon } from "@heroicons/react/solid";

const Error = ({ message, className = "", onRetry }) => {
  return (
    <div className={`flex flex-col justify-center items-center ${className}`}>
      <h4 className="text-danger">{message}</h4>
      <button
        onClick={onRetry}
        className="flex items-center justify-center bg-secondary text-white rounded-sm shadow-sm hover:bg-blue-400 px-6 mt-4 py-2"
      >
        <RefreshIcon className="w-6 mr-2" /> Try Again
      </button>
    </div>
  );
};

export default Error;

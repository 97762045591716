export const structureError = (error) =>
  error && error.response
    ? {
        status: error.response.status,
        message: error.response.data.message,
      }
    : {
        status: -1,
        message: error.message,
      };

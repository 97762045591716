import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Loader from "../../components/loader";
import Error from "../../components/error";
import Empty from "../../components/empty";

import { competitionsFetchStart } from "../../store/competition/reducer";

const Home = () => {
  const dispatch = useDispatch();
  const { fetchCompetitionsLoading, competitions, fetchCompetitionsError } =
    useSelector((state) => state.competition);

  const fetch = () => dispatch(competitionsFetchStart());

  useEffect(() => {
    fetch();
  }, []);

  const renderContent = () => {
    if (fetchCompetitionsError) {
      return (
        <Error
          className="h-96"
          message={fetchCompetitionsError.message}
          onRetry={fetch}
        />
      );
    } else if (fetchCompetitionsLoading || !competitions) {
      return <Loader className="h-96" title="Loading competitions ..." />;
    } else if (competitions.length === 0) {
      return <Empty className="my-36" message="No competition found" />;
    }

    return (
      <section className="mt-4 grid grid-cols-2 gap-4 ">
        {competitions.map((item) => (
          <Link key={item._id} to={`/competitions/${item._id}`}>
            <article
              style={{
                backgroundImage: `url(https://admin.play.et/api/containers/questionCategoryImages/download/516a6494-eb64-4a3a-9270-35310cd240e0.png)`,
              }}
              className="w-full h-40 bg-no-repeat bg-contain rounded-md overflow-hidden cursor-pointer  "
            >
              <div className="w-full h-full bg-black bg-opacity-40 flex flex-col justify-center items-center text-white">
                <h3 className="text-center font-montserrat-bold mb-4">
                  {item.name}
                </h3>
                <div className="bg-black bg-opacity-70 px-2 rounded-md">
                  <h4 className="text-center font-montserrat-light ">
                    {item.country ? item.country : item.federation}
                  </h4>
                </div>
              </div>
            </article>
          </Link>
        ))}
      </section>
    );
  };

  return (
    <div className="py-6 px-4 ">
      <h2 className="font-montserrat-bold text-2xl">Competitions</h2>
      {renderContent()}
    </div>
  );
};

export default Home;

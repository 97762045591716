import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  fetchCategoriesLoading: false,
  categories: null,
  page: 1,
  limit: 10,
  total: 0,
  loadMore: false,
  fetchCategoriesError: false,
};

const categorySlice = createSlice({
  name: "category",
  initialState: INITIAL_STATE,
  reducers: {
    categoriesFetchStart: (state, { payload: { loadMore = false } }) => {
      return {
        ...state,
        fetchCategoriesLoading: true,
        fetchCategoriesError: null,
        loadMore,
      };
    },
    categoriesFetchSuccess: (
      state,
      { payload: { categories, page, limit, total, loadMore = false } }
    ) => {
      return {
        ...state,
        fetchCategoriesLoading: false,
        categories: loadMore
          ? [...state.categories, ...categories]
          : categories,
        page,
        limit,
        total,
        loadMore: false,
      };
    },
    categoriesFetchError: (state, { payload: { error, loadMore = false } }) => {
      return {
        ...state,
        fetchCategoriesLoading: false,
        fetchCategoriesError: loadMore ? null : error,
        loadMore: false,
      };
    },
  },
});

export const {
  categoriesFetchStart,
  categoriesFetchSuccess,
  categoriesFetchError,
} = categorySlice.actions;

export default categorySlice.reducer;

import React from "react";
import { EmojiSadIcon } from "@heroicons/react/solid";

const Empty = ({ message = "No data found", className = "" }) => {
  return (
    <div className={`flex flex-col justify-center items-center ${className}`}>
      <EmojiSadIcon className="w-14 text-primary" />
      <h5 className="font-montserrat-semibold mt-4">{message}</h5>
    </div>
  );
};

export default Empty;

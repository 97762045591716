import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  fetchLeadersLoading: false,
  leaders: null,
  page: 1,
  limit: 10,
  total: 0,
  loadMore: false,
  rankBy: {
    label: "Today",
    value: "TODAY",
  },
  fetchLeadersError: false,
};

const leaderSlice = createSlice({
  name: "leader",
  initialState: INITIAL_STATE,
  reducers: {
    leadersFetchStart: (state, { payload: { loadMore = false } }) => {
      return {
        ...state,
        fetchLeadersLoading: true,
        fetchLeadersError: null,
        loadMore,
      };
    },
    leadersFetchSuccess: (
      state,
      { payload: { leaders, page, limit, total, loadMore = false } }
    ) => {
      return {
        ...state,
        fetchLeadersLoading: false,
        leaders: loadMore ? [...state.leaders, ...leaders] : leaders,
        page,
        limit,
        total,
        loadMore: false,
      };
    },
    leadersFetchError: (state, { payload: { error, loadMore = false } }) => {
      return {
        ...state,
        fetchLeadersLoading: false,
        fetchLeadersError: loadMore ? null : error,
        loadMore: false,
      };
    },
    setRankBy: (state, { payload: { rankBy } }) => {
      return {
        ...state,
        rankBy,
      };
    },
  },
});

export const {
  leadersFetchStart,
  leadersFetchSuccess,
  leadersFetchError,
  setRankBy,
} = leaderSlice.actions;

export default leaderSlice.reducer;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../../components/loader";
import Error from "../../components/error";
import Empty from "../../components/empty";

import { standingsFetchStart } from "../../store/competition/reducer";

const Standing = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { fetchStandingsLoading, standings, fetchStandingsError } = useSelector(
    (state) => state.competition
  );

  const fetch = () => dispatch(standingsFetchStart({ id: params.id }));

  useEffect(() => {
    fetch();
  }, [params.id]);

  const renderContent = () => {
    if (fetchStandingsError) {
      return (
        <Error
          className="h-96"
          message={fetchStandingsError.message}
          onRetry={fetch}
        />
      );
    } else if (fetchStandingsLoading || !standings) {
      return <Loader className="h-96" title="Loading standing table ..." />;
    } else if (standings.length === 0) {
      return (
        <Empty
          className="my-36"
          message="No standing table found for this season"
        />
      );
    }

    return (
      <table className="table-auto">
        <thead className="bg-gray-300 text-white h-8 text-sm">
          <tr>
            <th>#</th>
            <th>Team</th>
            <th>MP</th>
            <th>W</th>
            <th>L</th>
            <th>D</th>
            <th>Pts</th>
          </tr>
        </thead>
        <tbody>
          {standings.map((data) => (
            <tr key={data.team_id} className="h-8">
              <td>
                <div className="flex justify-center items-center  px-1 font-montserrat-thin">
                  {data.rank}
                </div>
              </td>
              <td>
                <div className="px-1 font-montserrat-medium">{data.name}</div>
              </td>
              <td>
                <div className=" flex justify-center items-center text-xs  px-2 ">
                  {data.matches}
                </div>
              </td>
              <td>
                <div className="bg-primary flex justify-center items-center text-xs rounded-md px-2 text-white">
                  {data.won}
                </div>
              </td>
              <td>
                <div className="bg-danger flex justify-center items-center text-xs rounded-md px-2 text-white">
                  {data.lost}
                </div>
              </td>
              <td>
                <div className="bg-gray-500 flex justify-center items-center text-xs rounded-md px-2 text-white">
                  {data.drawn}
                </div>
              </td>
              <td>
                <div className="flex justify-center items-center text-xs  px-2 font-montserrat-medium">
                  {data.points}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return <div>{renderContent()}</div>;
};

export default Standing;

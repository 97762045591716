import React from "react";
import BounceLoader from "../bounce-loader";

const LoadMore = ({ loading = false, disabled = false, text, onClick }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`flex items-center justify-center ${
        disabled ? "bg-gray-400" : "bg-secondary hover:bg-blue-400"
      } text-white rounded-sm shadow-sm px-6 mt-4 py-2`}
    >
      {loading && <BounceLoader color="bg-white" className="mr-3" />}
      {loading ? "Loading" : text || "Load More"}
    </button>
  );
};

export default LoadMore;

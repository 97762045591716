import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  fetchQuestionsLoading: false,
  submitAnswerLoading: false,
  submitAnswerSuccess: false,
  questions: null,
  category: null,
  point: 0,
  fetchQuestionsError: false,
  submitanswerError: null,
};

const quizSlice = createSlice({
  name: "quiz",
  initialState: INITIAL_STATE,
  reducers: {
    questionsFetchStart: (state) => {
      return {
        ...state,
        fetchQuestionsLoading: true,
        fetchQuestionsError: null,
      };
    },
    questionsFetchSuccess: (state, { payload: { questions, category } }) => {
      return {
        ...state,
        fetchQuestionsLoading: false,
        questions,
        category,
      };
    },
    questionsFetchError: (state, { payload: { error } }) => {
      return {
        ...state,
        fetchQuestionsLoading: false,
        fetchQuestionsError: error,
      };
    },
    answerSubmitStart: (state) => {
      return {
        ...state,
        submitAnswerLoading: true,
        submitanswerError: null,
      };
    },
    answerSubmitSuccess: (state, { payload: { point } }) => {
      return {
        ...state,
        submitAnswerLoading: false,
        submitAnswerSuccess: true,
        point,
      };
    },
    answerSubmitError: (state, { payload: { error } }) => {
      return {
        ...state,
        submitAnswerLoading: false,
        submitanswerError: error,
      };
    },
    answerSubmitClear: (state) => {
      return {
        ...state,
        submitAnswerLoading: false,
        submitanswerError: null,
        point: 0,
      };
    },
  },
});

export const {
  questionsFetchStart,
  questionsFetchSuccess,
  questionsFetchError,
  answerSubmitStart,
  answerSubmitSuccess,
  answerSubmitError,
  answerSubmitClear,
} = quizSlice.actions;

export default quizSlice.reducer;

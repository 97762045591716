import { all, call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";

import {
  categoriesFetchStart,
  categoriesFetchSuccess,
  categoriesFetchError,
} from "./reducer";
import { structureError } from "../../utils/error-factory";

function* fetchCategoriesAsync({ payload }) {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { categories, page, limit, totalDocs },
      },
    } = yield axios.get(`/categories`, {
      params: {
        _page: payload.page,
        _limit: payload.limit,
        published: true,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(
      categoriesFetchSuccess({
        categories,
        page,
        limit,
        total: totalDocs,
        loadMore: payload.loadMore,
      })
    );
  } catch (error) {
    yield put(
      categoriesFetchError({
        error: structureError(error),
        loadMore: payload.loadMore,
      })
    );
  }
}

function* onFetchCategories() {
  yield takeLatest(categoriesFetchStart.type, fetchCategoriesAsync);
}

function* categorySagas() {
  yield all([call(onFetchCategories)]);
}

export default categorySagas;

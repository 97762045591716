import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import Error from "../../components/error";

import { profileFetchStart } from "../../store/auth/reducer";

const Splash = () => {
  const dispatch = useDispatch();
  const { fetchProfileError } = useSelector((state) => state.auth);

  const error = fetchProfileError;

  const handleRetry = () => {
    if (fetchProfileError) {
      dispatch(profileFetchStart());
    }
  };
  if (error) {
    return (
      <Error
        message={error.message}
        className="w-full h-full"
        onRetry={handleRetry}
      />
    );
  }
  return <Loader className="w-full h-full" title="Getting things ready ..." />;
};

export default Splash;

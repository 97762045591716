import { all, call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";

import {
  questionsFetchStart,
  questionsFetchSuccess,
  questionsFetchError,
  answerSubmitStart,
  answerSubmitSuccess,
  answerSubmitError,
} from "./reducer";
import { structureError } from "../../utils/error-factory";

function* fetchQuestionsAsync({ payload }) {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { questions, category },
      },
    } = yield axios.get(`/categories/${payload.categoryId}/questions/quiz`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(
      questionsFetchSuccess({
        questions,
        category,
      })
    );
  } catch (error) {
    yield put(
      questionsFetchError({
        error: structureError(error),
      })
    );
  }
}

function* submitAnswerAsync({ payload }) {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { point },
      },
    } = yield axios.post(`/questions/quiz`, payload.data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(
      answerSubmitSuccess({
        point,
      })
    );
  } catch (error) {
    yield put(
      answerSubmitError({
        error: structureError(error),
      })
    );
  }
}

function* onFetchQuestions() {
  yield takeLatest(questionsFetchStart.type, fetchQuestionsAsync);
}

function* onSubmitAnswer() {
  yield takeLatest(answerSubmitStart.type, submitAnswerAsync);
}

function* quizSagas() {
  yield all([call(onFetchQuestions), call(onSubmitAnswer)]);
}

export default quizSagas;

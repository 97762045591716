export const TABS = [
  {
    key: "LIVE_SCORE",
    value: "Live Score",
  },
  {
    key: "STANDING",
    value: "Standing",
  },
  {
    key: "HISTORY",
    value: "History",
  },
];

import { all, call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";

import {
  leadersFetchStart,
  leadersFetchSuccess,
  leadersFetchError,
} from "./reducer";
import { structureError } from "../../utils/error-factory";

function* fetchLeadersAsync({ payload }) {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { leaders, page, limit, totalDocs },
      },
    } = yield axios.get(`/leader-board`, {
      params: {
        _page: payload.page,
        _limit: payload.limit,
        rankBy: payload.rankBy,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(
      leadersFetchSuccess({
        leaders,
        page,
        limit,
        total: totalDocs,
        loadMore: payload.loadMore,
      })
    );
  } catch (error) {
    yield put(
      leadersFetchError({
        error: structureError(error),
        loadMore: payload.loadMore,
      })
    );
  }
}

function* onFetchLeaders() {
  yield takeLatest(leadersFetchStart.type, fetchLeadersAsync);
}

function* leaderSagas() {
  yield all([call(onFetchLeaders)]);
}

export default leaderSagas;

import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  fetchFeedsLoading: false,
  fetchFeedLoading: false,
  feeds: null,
  feed: null,
  page: 1,
  limit: 10,
  total: 0,
  loadMore: false,
  fetchFeedsError: null,
  fetchFeedError: null,
};

const feedSlice = createSlice({
  name: "feed",
  initialState: INITIAL_STATE,
  reducers: {
    feedsFetchStart: (state, { payload: { loadMore = false } }) => {
      return {
        ...state,
        fetchFeedsLoading: true,
        fetchFeedsError: null,
        loadMore,
      };
    },
    feedsFetchSuccess: (
      state,
      { payload: { feeds, page, limit, total, loadMore = false } }
    ) => {
      return {
        ...state,
        fetchFeedsLoading: false,
        feeds: loadMore ? [...state.feeds, ...feeds] : feeds,
        page,
        limit,
        total,
        loadMore: false,
      };
    },
    feedsFetchError: (state, { payload: { error, loadMore = false } }) => {
      return {
        ...state,
        fetchFeedsLoading: false,
        fetchFeedsError: loadMore ? null : error,
        loadMore: false,
      };
    },
    // GET FEED
    feedFetchStart: (state) => {
      return {
        ...state,
        fetchFeedLoading: true,
        fetchFeedError: null,
      };
    },
    feedFetchSuccess: (state, { payload: { feed } }) => {
      return {
        ...state,
        fetchFeedLoading: false,
        feed,
      };
    },
    feedFetchError: (state, { payload: { error } }) => {
      return {
        ...state,
        fetchFeedLoading: false,
        fetchFeedError: error,
      };
    },
  },
});

export const {
  feedsFetchStart,
  feedsFetchSuccess,
  feedsFetchError,
  feedFetchStart,
  feedFetchSuccess,
  feedFetchError,
} = feedSlice.actions;

export default feedSlice.reducer;

import {
  QuestionMarkCircleIcon,
  RssIcon,
  UserCircleIcon,
  HomeIcon,
} from "@heroicons/react/solid";
export const TABS = [
  {
    key: "HOME",
    value: <HomeIcon className="w-8" />,
  },
  {
    key: "FEED",
    value: <RssIcon className="w-8" />,
  },
  {
    key: "QUIZ",
    value: <QuestionMarkCircleIcon className="w-8" />,
  },
  {
    key: "PROFILE",
    value: <UserCircleIcon className="w-8" />,
  },
];

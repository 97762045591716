import { useState } from "react";
import { Tab } from "@headlessui/react";

import LiveScore from "../../components/live-score";
import Standing from "../../components/standing";
import History from "../../components/history";

import { TABS } from "./data";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Competition = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="px-4 py-6">
      <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
        <Tab.List className="flex space-x-1 rounded-xl bg-light-primary p-1">
          {TABS.map((tab) => (
            <Tab
              key={tab.key}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-montserrat-medium leading-5 ",
                  "focus:outline-none",
                  selected
                    ? "bg-white shadow text-primary"
                    : "text-white hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {tab.value}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-6">
          <Tab.Panel>{activeTab === 0 && <LiveScore />}</Tab.Panel>
          <Tab.Panel>{activeTab === 1 && <Standing />}</Tab.Panel>
          <Tab.Panel>{activeTab === 2 && <History />}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Competition;

import { Tab } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { TABS } from "./data";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const Competition = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getSeletedIndex = () => {
    if (location.pathname.includes("feeds")) {
      return 1;
    } else if (location.pathname.includes("quizzes")) {
      return 2;
    } else if (
      location.pathname.includes("profile") ||
      location.pathname.includes("leader-board")
    ) {
      return 3;
    } else {
      return 0;
    }
  };

  return (
    <div className="sticky bottom-0 shadow-xl">
      <Tab.Group
        selectedIndex={getSeletedIndex()}
        onChange={(index) => {
          switch (index) {
            case 0:
              return navigate("/");
            case 1:
              return navigate("/feeds");
            case 2:
              return navigate("/quizzes");
            case 3:
              return navigate("/profile");
          }
        }}
      >
        <Tab.List className="flex space-x-1 py-2 bg-light-primary p-1">
          {TABS.map((tab) => (
            <Tab
              key={tab.key}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-montserrat-medium leading-5 ",
                  "focus:outline-none flex justify-center",
                  selected
                    ? "bg-white shadow text-primary"
                    : "text-white hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {tab.value}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
};

export default Competition;

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import axios from "axios";
import Cookies from "js-cookie";

import {
  loginStart,
  loginSuccess,
  loginError,
  profileFetchStart,
  profileFetchSuccess,
  profileFetchError,
  profileUpdateStart,
  profileUpdateSuccess,
  profileUpdateError,
  sessionRehydrateStart,
  sessionRehydrateSuccess,
  sessionRehydrateError,
  logOut,
} from "./reducer";
import { structureError } from "../../utils/error-factory";

function* loginAsync({ payload: { phoneNumber, password } }) {
  try {
    const {
      data: {
        data: { token, user },
      },
    } = yield axios.post(`/auth/login`, {
      phoneNumber,
      password,
    });

    Cookies.set(
      "@PLAYET_AUTH",
      JSON.stringify({
        token,
      }),
      { expires: 30 }
    );

    yield put(
      loginSuccess({
        token,
        user,
      })
    );
  } catch (error) {
    yield put(
      loginError({
        error: structureError(error),
      })
    );
  }
}

function* fetchProfileAsync() {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { user },
      },
    } = yield axios.get(`/auth/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(
      profileFetchSuccess({
        user,
      })
    );
  } catch (error) {
    yield put(
      profileFetchError({
        error: structureError(error),
      })
    );
  }
}

function* updateProfileAsync({ payload }) {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { user },
      },
    } = yield axios.patch(`/auth/profile`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(
      profileUpdateSuccess({
        user,
      })
    );
  } catch (error) {
    yield put(
      profileUpdateError({
        error: structureError(error),
      })
    );
  }
}

function* rehydrateSessionAsync() {
  try {
    const session =
      Cookies.get("@PLAYET_AUTH") || JSON.stringify({ token: null });
    const { token } = JSON.parse(session);
    yield put(
      sessionRehydrateSuccess({
        token,
      })
    );
  } catch (error) {
    yield put(sessionRehydrateError());
  }
}

function* logOutAsync() {
  yield Cookies.remove("@PLAYET_AUTH");
}

function* onLogin() {
  yield takeLatest(loginStart.type, loginAsync);
}

function* onFetchProfile() {
  yield takeLatest(profileFetchStart.type, fetchProfileAsync);
}

function* onUpdateProfile() {
  yield takeLatest(profileUpdateStart.type, updateProfileAsync);
}

function* onRehydrateSession() {
  yield takeLatest(sessionRehydrateStart.type, rehydrateSessionAsync);
}

function* onLogOut() {
  yield takeLatest(logOut.type, logOutAsync);
}

function* authSagas() {
  yield all([
    call(onLogin),
    call(onFetchProfile),
    call(onUpdateProfile),
    call(onRehydrateSession),
    call(onLogOut),
  ]);
}

export default authSagas;

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { InformationCircleIcon } from "@heroicons/react/solid";

import CustomButton from "../../components/custom-button";

import { loginStart } from "../../store/auth/reducer";

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { loginLoading, loginError, token } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (token) {
      const from = location.state?.from?.pathname || "/";
      navigate(from, { replace: true });
    }
  }, [token]);

  const onSubmit = (values) => {
    const { phoneNumber, password } = values;
    dispatch(loginStart({ phoneNumber, password }));
  };

  return (
    <div className="flex flex-col  justify-center h-full px-4 ">
      <h3 className="font-montserrat-bold text-3xl mb-2">Login</h3>
      <h5 className="font-montserrat-light text-lg">Welcome to 9872 </h5>

      <form className="my-8" onSubmit={handleSubmit(onSubmit)}>
        {loginError && (
          <div className="flex bg-red-300 text-red-800 rounded-md px-4 py-2 mb-4">
            <InformationCircleIcon className="w-5 mr-2" />
            {loginError.message}
          </div>
        )}
        <div className="mb-8">
          <input
            {...register("phoneNumber", {
              required: {
                value: true,
                message: "Please input phone number",
              },
            })}
            type="text"
            placeholder="Phone Number"
            className="border-0 ring-primary ring-2  rounded-full px-4 py-2 focus:outline-none  focus:ring-2 focus:border-0 focus:ring-primary w-full"
          />
          {errors.phoneNumber && (
            <p className="flex text-danger text-sm font-montserrat-light mt-2">
              <InformationCircleIcon className="w-4 mr-1" />
              {errors.phoneNumber.message}
            </p>
          )}
        </div>

        <div className="mb-8">
          <input
            {...register("password", {
              required: {
                value: true,
                message: "Please input password",
              },
            })}
            placeholder="Password"
            type="password"
            className="border-0 ring-primary ring-2  rounded-full px-4 py-2 focus:outline-none  focus:ring-2 focus:border-0 focus:ring-primary w-full"
          />
          {errors.password && (
            <p className="flex text-danger text-sm font-montserrat-light mt-2">
              <InformationCircleIcon className="w-4 mr-1" />
              {errors.password.message}
            </p>
          )}
        </div>

        <div>
          <CustomButton
            loading={loginLoading}
            disabled={loginLoading}
            text="Login"
            className="text-white w-full rounded-full py-2 font-montserrat-medium "
          />
        </div>
      </form>
      <div>
        <h4 className="font-montserrat-medium text-center mb-2">
          Enjoy the Best Sport contents, Play and Win!
        </h4>
        <div className="ring-1 ring-gray-200 rounded-md p-4">
          <p className="text-gray-500 mb-2 text-sm font-bold">
            3 days free and only 2 birr per day
          </p>
          <p className="text-gray-500 mb-2  text-sm font-bold">
            Unsubscribe anytime at the portal or send Stop to 9872
          </p>
          <p className="text-gray-500  text-sm font-bold">
            For more help please contact 0911102256
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { InformationCircleIcon } from "@heroicons/react/solid";

import CustomButton from "../../components/custom-button";

import { profileUpdateStart } from "../../store/auth/reducer";

const ProfileSetup = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { updateProfileLoading, updateProfileError } = useSelector(
    (state) => state.auth
  );

  const onSubmit = (values) => {
    const { firstName, lastName } = values;
    dispatch(profileUpdateStart({ firstName, lastName }));
  };

  return (
    <div className="flex flex-col  justify-center h-full px-4 ">
      <h3 className="font-montserrat-bold text-3xl mb-2">Setup Profile</h3>
      <h5 className="font-montserrat-light text-lg">
        you're almost done, provide profile information{" "}
      </h5>

      <form className="my-8" onSubmit={handleSubmit(onSubmit)}>
        {updateProfileError && (
          <div className="flex bg-red-300 text-red-800 rounded-md px-4 py-2 mb-4">
            <InformationCircleIcon className="w-5 mr-2" />
            {updateProfileError.message}
          </div>
        )}
        <div className="mb-8">
          <input
            {...register("firstName", {
              required: {
                value: true,
                message: "Please input phone number",
              },
            })}
            type="text"
            placeholder="First Name"
            className="border-0 ring-primary ring-2  rounded-full px-4 py-2 focus:outline-none  focus:ring-2 focus:border-0 focus:ring-primary w-full"
          />
          {errors.firstName && (
            <p className="flex text-danger text-sm font-montserrat-light mt-2">
              <InformationCircleIcon className="w-4 mr-1" />
              {errors.firstName.message}
            </p>
          )}
        </div>

        <div className="mb-8">
          <input
            {...register("lastName", {
              required: {
                value: true,
                message: "Please input password",
              },
            })}
            placeholder="Last Name"
            type="text"
            className="border-0 ring-primary ring-2  rounded-full px-4 py-2 focus:outline-none  focus:ring-2 focus:border-0 focus:ring-primary w-full"
          />
          {errors.lastName && (
            <p className="flex text-danger text-sm font-montserrat-light mt-2">
              <InformationCircleIcon className="w-4 mr-1" />
              {errors.lastName.message}
            </p>
          )}
        </div>

        <div>
          <CustomButton
            loading={updateProfileLoading}
            disabled={updateProfileLoading}
            text="Submit"
            className="text-white w-full rounded-full py-2 font-montserrat-medium "
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileSetup;

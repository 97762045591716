import React from "react";
import { Link } from "react-router-dom";
import LanguageSelector from "../language-selector";

const Header = () => {
  return (
    <header className="flex justify-between items-center p-4 shadow-md sticky top-0 bg-white">
      <Link to="/" className="font-montserrat-bold text-2xl text-secondary">
        Play.et
      </Link>
      {/* <LanguageSelector /> */}
    </header>
  );
};

export default Header;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RankSelector from "../../components/rank-selector";

import Loader from "../../components/loader";
import Error from "../../components/error";
import Empty from "../../components/empty";
import LoadMore from "../../components/load-more";

import { leadersFetchStart } from "../../store/leader/reducer";

const LeaderBoard = () => {
  const dispatch = useDispatch();
  const {
    fetchLeadersLoading,
    fetchLeadersError,
    leaders,
    page,
    limit,
    total,
    loadMore,
    rankBy,
  } = useSelector((state) => state.leader);

  const fetch = (page = 1, loadMore = false) => {
    dispatch(
      leadersFetchStart({
        page,
        limit,
        loadMore,
        rankBy: rankBy.value,
      })
    );
  };

  useEffect(() => {
    fetch();
  }, [rankBy]);

  const renderContent = () => {
    if (fetchLeadersError) {
      return (
        <Error
          className="h-96"
          message={fetchLeadersError.message}
          onRetry={fetch}
        />
      );
    } else if ((fetchLeadersLoading || !leaders) && !loadMore) {
      return <Loader className="h-96" title="Loading leader board ..." />;
    } else if (leaders.length === 0) {
      return (
        <Empty
          className="my-36"
          message={`No leader board for ${rankBy.label}`}
        />
      );
    } else {
      return (
        <>
          <section className="my-6 flex flex-col gap-2">
            {leaders.map((item, index) => (
              <article
                key={item.user._id}
                className={`flex justify-between p-2 rounded-md ${
                  index % 2 === 0 ? "" : "bg-yellow-200 text-yellow-800"
                }`}
              >
                <h4>
                  {index + 1}. {item.user.firstName} {item.user.lastName}
                </h4>
                <h4 className="font-montserrat-medium">{item.point} Point</h4>
              </article>
            ))}
          </section>
          {leaders.length !== total && (
            <div className="flex justify-center mt-6">
              <LoadMore
                loading={fetchLeadersLoading}
                disabled={fetchLeadersLoading}
                onClick={() => fetch(page + 1, true)}
              />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="px-4 py-6">
      <h3 className="text-black text-2xl font-montserrat-bold mb-2">
        Leader Board
      </h3>
      <div className="flex justify-end">
        <RankSelector />
      </div>
      {renderContent()}
    </div>
  );
};

export default LeaderBoard;

import { createSlice } from "@reduxjs/toolkit";
import { answerSubmitSuccess } from "../quiz/reducer";

const INITIAL_STATE = {
  loginLoading: false,
  fetchProfileLoading: false,
  updateProfileLoading: false,
  updateProfileSuccess: false,
  user: null,
  token: null,
  sessionHydrated: false,
  loginError: null,
  fetchProfileError: null,
  updateProfileError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    loginStart: (state) => {
      return {
        ...state,
        loginLoading: true,
        loginError: null,
      };
    },
    loginSuccess: (state, { payload: { token, user } }) => {
      return {
        ...state,
        loginLoading: false,
        user,
        token,
      };
    },
    loginError: (state, { payload: { error } }) => {
      return {
        ...state,
        loginLoading: false,
        loginError: error,
      };
    },
    profileFetchStart: (state) => {
      return {
        ...state,
        fetchProfileLoading: true,
        fetchProfileError: null,
      };
    },
    profileFetchSuccess: (state, { payload: { user } }) => {
      return {
        ...state,
        fetchProfileLoading: false,
        user,
      };
    },
    profileFetchError: (state, { payload: { error } }) => {
      return {
        ...state,
        fetchProfileLoading: false,
        fetchProfileError: error,
      };
    },
    profileUpdateStart: (state) => {
      return {
        ...state,
        updateProfileLoading: true,
        updateProfileError: null,
        updateProfileSuccess: false,
      };
    },
    profileUpdateSuccess: (state, { payload: { user } }) => {
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileSuccess: true,
        user,
      };
    },
    profileUpdateError: (state, { payload: { error } }) => {
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileError: error,
      };
    },
    clearProfileUpdateError: (state) => {
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileError: null,
      };
    },
    sessionRehydrateStart: (state) => {
      return {
        ...state,
      };
    },
    sessionRehydrateSuccess: (state, { payload: { token } }) => {
      return {
        ...state,
        token,
        sessionHydrated: true,
      };
    },
    sessionRehydrateError: (state) => {
      return {
        ...state,
        sessionHydrated: true,
      };
    },
    locationFetchStart: (state) => {
      return {
        ...state,
        fetchLocationLoading: true,
        fetchLocationError: null,
      };
    },
    locationFetchSuccess: (state, { payload: { location } }) => {
      return {
        ...state,
        fetchLocationLoading: false,
        location,
      };
    },
    locationFetchError: (state, { payload: { error } }) => {
      return {
        ...state,
        fetchLocationLoading: false,
        fetchLocationError: error,
      };
    },
    logOut: (state) => {
      return {
        ...state,
        loginLoading: false,
        fetchProfileLoading: false,
        updateProfileLoading: false,
        updateProfileSuccess: false,
        user: null,
        token: null,
        loginError: null,
        fetchProfileError: null,
        updateProfileError: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(answerSubmitSuccess, (state, { payload: { point } }) => {
      return {
        ...state,
        user: {
          ...state.user,
          point: state.user.point + point,
        },
      };
    });
  },
});

export const {
  loginStart,
  loginSuccess,
  loginError,
  profileFetchStart,
  profileFetchSuccess,
  profileFetchError,
  profileUpdateStart,
  profileUpdateSuccess,
  profileUpdateError,
  clearProfileUpdateError,
  sessionRehydrateStart,
  sessionRehydrateSuccess,
  sessionRehydrateError,
  logOut,
} = authSlice.actions;

export default authSlice.reducer;

import { all, call } from "redux-saga/effects";
import authSagas from "./auth/sagas";
import competitionSagas from "./competition/sagas";
import categorySagas from "./category/sagas";
import quizSagas from "./quiz/sagas";
import leaderSagas from "./leader/sagas";
import feedSagas from "./feed/sagas";

function* rootSaga() {
  yield all([
    call(authSagas),
    call(competitionSagas),
    call(categorySagas),
    call(quizSagas),
    call(leaderSagas),
    call(feedSagas),
  ]);
}

export default rootSaga;

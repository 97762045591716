import React from "react";
import { Link } from "react-router-dom";

const OnBoarding = () => {
  return (
    <div className="py-6 px-4 flex flex-col justify-center items-center h-full text-center ">
      <h2 className="text-6xl mb-12 font-montserrat-bold">Play.et</h2>
      <div className="mb-12">
        <h3 className="font-montserrat-bold text-2xl my-2">WELCOME</h3>
        <h5 className="font-montserrat-medium text-sm">
          Experience the best sports app ever
        </h5>
      </div>
      <div className="flex gap-2 text-sm font-montserrat-medium mb-6">
        <h6>HAVE AN ACCOUNT</h6>
        <Link to="/login" className="text-blue-600">
          SIGN IN
        </Link>
      </div>
      <div className="text-xs font-montserrat-light mb-5">
        <p className="text-gray-500 mb-1">
          By tapping "Get Started", you agree to our
        </p>
        <p className="text-blue-600">Terms of use and Privacy Policy.</p>
      </div>
      <Link
        to="/login"
        className="bg-green-600 w-full py-3 rounded-full text-white font-montserrat-bold hover:bg-green-500"
      >
        GET STARTED
      </Link>
    </div>
  );
};

export default OnBoarding;

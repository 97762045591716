import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../../components/loader";
import Error from "../../components/error";
import Empty from "../../components/empty";
import LoadMore from "../../components/load-more";

import { historiesFetchStart } from "../../store/competition/reducer";

const History = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    fetchHistoriesLoading,
    histories,
    historiesPage: page,
    historiesTotal: total,
    historiesLoadMore: loadMore,
    fetchHistoriesError,
  } = useSelector((state) => state.competition);

  const fetch = (page = 1, loadMore = false) =>
    dispatch(historiesFetchStart({ id: params.id, page, loadMore }));

  useEffect(() => {
    fetch();
  }, [params.id]);

  const renderContent = () => {
    if (fetchHistoriesError) {
      return (
        <Error
          className="h-96"
          message={fetchHistoriesError.message}
          onRetry={fetch}
        />
      );
    } else if ((fetchHistoriesLoading || !histories) && !loadMore) {
      return <Loader className="h-96" title="Loading match history ..." />;
    } else if (histories.length === 0) {
      return <Empty className="my-36" message="No match history" />;
    }

    return (
      <>
        <section className="flex flex-col gap-4">
          {histories.map((item) => (
            <article
              key={item.id}
              className="shadow-md p-2 rounded-md flex flex-col items-center cursor-pointer "
            >
              <div className="flex justify-between self-stretch">
                <div className="bg-secondary flex justify-center items-center text-white text-sm font-montserrat-medium w-max px-2 rounded-sm">
                  <p>{item.status}</p>
                </div>
                <div className="bg-danger flex justify-center items-center text-white px-2 font-montserrat-medium rounded-sm">
                  <p>{item.time}</p>
                </div>
              </div>

              <h5 className="text-lg font-montserrat-medium my-4">
                {item.competition_name}
              </h5>
              <div className="grid grid-cols-5 gap-2">
                <h5 className="text-sm font-montserrat-medium col-span-2 text-left">
                  {item.home_name}
                </h5>
                <div className="border col-span-1 text-sm  border-light-terinary rounded-sm mb-4 px-3 font-montserrat-bold">
                  <h3 className="">{item.score}</h3>
                </div>
                <h5 className="text-sm font-montserrat-medium col-span-2 text-right">
                  {item.away_name}
                </h5>
              </div>
            </article>
          ))}
        </section>
        {histories.length !== total && (
          <div className="flex justify-center mt-6">
            <LoadMore
              loading={fetchHistoriesLoading}
              disabled={fetchHistoriesLoading}
              onClick={() => fetch(page + 1, true)}
            />
          </div>
        )}
      </>
    );
  };
  return <div>{renderContent()}</div>;
};

export default History;

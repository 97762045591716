import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../../components/loader";
import Error from "../../components/error";
import Empty from "../../components/empty";

import { liveScoresFetchStart } from "../../store/competition/reducer";

const LiveScore = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { fetchLiveScoreLoading, liveScores, fetchLiveScoreError } =
    useSelector((state) => state.competition);

  const fetch = () => dispatch(liveScoresFetchStart({ id: params.id }));

  useEffect(() => {
    fetch();
  }, [params.id]);

  const renderContent = () => {
    if (fetchLiveScoreError) {
      return (
        <Error
          className="h-96"
          message={fetchLiveScoreError.message}
          onRetry={fetch}
        />
      );
    } else if (fetchLiveScoreLoading || !liveScores) {
      return <Loader className="h-96" title="Loading live scores ..." />;
    } else if (liveScores.length === 0) {
      return <Empty className="my-36" message="No live matchs found" />;
    }

    return (
      <section className="flex flex-col gap-4">
        {liveScores.map((item) => (
          <article
            key={item.id}
            className="shadow-md p-2 rounded-md flex flex-col items-center cursor-pointer "
          >
            <div className="flex justify-between self-stretch">
              <div className="bg-secondary flex justify-center items-center text-white text-sm font-montserrat-medium w-max px-2 rounded-sm">
                <p>{item.status}</p>
              </div>
              <div className="bg-danger flex justify-center items-center text-white px-2 font-montserrat-medium rounded-sm">
                <p>{item.time}</p>
              </div>
            </div>

            <h5 className="text-lg font-montserrat-medium my-4">
              {item.competition_name}
            </h5>
            <div className="grid grid-cols-5 gap-2">
              <h5 className="text-sm font-montserrat-medium col-span-2 text-left">
                {item.home_name}
              </h5>
              <div className="border col-span-1 text-sm  border-light-terinary rounded-sm mb-4 px-3 font-montserrat-bold">
                <h3 className="">{item.score}</h3>
              </div>
              <h5 className="text-sm font-montserrat-medium col-span-2 text-right">
                {item.away_name}
              </h5>
            </div>
          </article>
        ))}
      </section>
    );
  };
  return <div>{renderContent()}</div>;
};

export default LiveScore;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Loader from "../../components/loader";
import Error from "../../components/error";
import Empty from "../../components/empty";
import LoadMore from "../../components/load-more";
import { IMAGE_BASE_URL } from "../../utils/constants";

import { feedsFetchStart } from "../../store/feed/reducer";

const Feed = () => {
  const dispatch = useDispatch();
  const {
    fetchFeedsLoading,
    fetchFeedsError,
    feeds,
    page,
    limit,
    total,
    loadMore,
  } = useSelector((state) => state.feed);

  const fetch = (page = 1, loadMore = false) => {
    dispatch(
      feedsFetchStart({
        page,
        limit,
        loadMore,
      })
    );
  };

  useEffect(() => {
    fetch();
  }, []);

  const renderContent = () => {
    if (fetchFeedsError) {
      return (
        <Error
          className="h-96"
          message={fetchFeedsError.message}
          onRetry={fetch}
        />
      );
    } else if ((fetchFeedsLoading || !feeds) && !loadMore) {
      return <Loader className="h-96" title="Loading feeds ..." />;
    } else if (feeds.length === 0) {
      return <Empty className="my-36" message="No feed found" />;
    } else {
      return (
        <>
          <article className="flex flex-col gap-4">
            {feeds.map((item) => (
              <Link
                to={`/feeds/${item._id}`}
                key={item._id}
                className="rounded-md shadow-md p-3"
              >
                <h2 className="font-montserrat-bold text-2xl">{item.title}</h2>
                <p className="font-montserrat-thin mt-1">{item.subTitle}</p>
                <img
                  className="w-full object-cover h-40 mt-2 rounded-md"
                  src={`${IMAGE_BASE_URL}/feed/${item.image}`}
                  alt={item.title}
                />
              </Link>
            ))}
          </article>

          {feeds.length !== total && (
            <div className="flex justify-center mt-6">
              <LoadMore
                loading={fetchFeedsLoading}
                disabled={fetchFeedsLoading}
                onClick={() => fetch(page + 1, true)}
              />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="px-4 py-6">
      <h2 className="font-montserrat-bold text-2xl mb-6">Feeds</h2>
      {renderContent()}
    </div>
  );
};

export default Feed;

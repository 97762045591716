import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PuzzleIcon } from "@heroicons/react/solid";

import Loader from "../../components/loader";
import Error from "../../components/error";
import Empty from "../../components/empty";
import LoadMore from "../../components/load-more";
import { IMAGE_BASE_URL } from "../../utils/constants";

import { categoriesFetchStart } from "../../store/category/reducer";

const Quiz = () => {
  const dispatch = useDispatch();
  const {
    fetchCategoriesLoading,
    fetchCategoriesError,
    categories,
    page,
    limit,
    total,
    loadMore,
  } = useSelector((state) => state.category);

  const fetch = (page = 1, loadMore = false) => {
    dispatch(
      categoriesFetchStart({
        page,
        limit,
        loadMore,
      })
    );
  };

  useEffect(() => {
    fetch();
  }, []);

  const renderContent = () => {
    if (fetchCategoriesError) {
      return (
        <Error
          className="h-96"
          message={fetchCategoriesError.message}
          onRetry={fetch}
        />
      );
    } else if ((fetchCategoriesLoading || !categories) && !loadMore) {
      return <Loader className="h-96" title="Loading categories ..." />;
    } else if (categories.length === 0) {
      return <Empty className="my-36" message="No quize category found" />;
    } else {
      return (
        <>
          <section className="grid grid-cols-2 gap-4">
            {categories.map((item) => (
              <article
                key={item._id}
                style={{
                  backgroundImage: `url(${IMAGE_BASE_URL}/category/${item.image})`,
                }}
                className="w-full h-40 bg-no-repeat bg-cover rounded-md overflow-hidden"
              >
                <div className="w-full h-full bg-black bg-opacity-40 flex flex-col justify-center items-center">
                  <div className="flex-grow flex items-center">
                    <Link
                      to={`/quizzes/${item._id}`}
                      className="flex  justify-center items-center bg-black bg-opacity-70 hover:bg-opacity-50 px-6 py-2 rounded-full cursor-pointer ring-2 ring-white"
                    >
                      <PuzzleIcon className="text-white w-6 mr-2" />
                      <h6 className="text-white font-montserrat-bold">Play</h6>
                    </Link>
                  </div>
                  <h4 className="text-white  font-montserrat-bold mb-4">
                    {item.name.en}
                  </h4>
                </div>
              </article>
            ))}
          </section>
          {categories.length !== total && (
            <div className="flex justify-center mt-6">
              <LoadMore
                loading={fetchCategoriesLoading}
                disabled={fetchCategoriesLoading}
                onClick={() => fetch(page + 1, true)}
              />
            </div>
          )}
        </>
      );
    }
  };
  return (
    <div className="p-4">
      <div>
        <h3 className="text-black text-2xl font-montserrat-bold mb-2">
          Take Quizzes, Get Rewards
        </h3>
        <h5 className="text-gray-400 font-montserrat-medium mb-4">
          Question Categories
        </h5>
      </div>
      {renderContent()}
    </div>
  );
};

export default Quiz;

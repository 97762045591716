import React from "react";
import BounceLoader from "../bounce-loader";

const CustomButton = ({
  loading = false,
  disabled = false,
  text,
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={`${className} flex items-center justify-center ${
        disabled ? "bg-gray-400" : "bg-primary  hover:bg-light-primary"
      } text-white  shadow-sm px-6 mt-4 py-2 `}
    >
      {loading && <BounceLoader color="bg-white" className="mr-3" />}
      {loading ? "Loading" : text}
    </button>
  );
};

export default CustomButton;

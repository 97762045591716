import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../../components/loader";
import Error from "../../components/error";

import { IMAGE_BASE_URL } from "../../utils/constants";

import { feedFetchStart } from "../../store/feed/reducer";

const FeedDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { fetchFeedLoading, fetchFeedError, feed } = useSelector(
    (state) => state.feed
  );

  const fetch = () => {
    dispatch(
      feedFetchStart({
        id: params.id,
      })
    );
  };

  useEffect(() => {
    fetch();
  }, []);

  if (fetchFeedError) {
    return (
      <Error
        className="h-96"
        message={fetchFeedError.message}
        onRetry={fetch}
      />
    );
  }
  if (fetchFeedLoading || !feed) {
    return <Loader className="h-96" title="Loading feed ..." />;
  }
  return (
    <div className="px-4 py-6">
      <img
        className="w-full object-cover h-40 mb-4 rounded-md"
        src={`${IMAGE_BASE_URL}/feed/${feed.image}`}
        alt={feed.title}
      />
      <h2 className="font-montserrat-bold text-2xl">{feed.title}</h2>
      <p className="font-montserrat-thin mt-1">{feed.subTitle}</p>
      <p className="mt-4 font-montserrat-medium text-gray-700">
        {feed.description}
      </p>
    </div>
  );
};

export default FeedDetail;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Countdown from "react-countdown";

import Loader from "../../components/loader";
import Error from "../../components/error";

import {
  questionsFetchStart,
  answerSubmitStart,
  answerSubmitClear,
} from "../../store/quiz/reducer";

const QuizStart = () => {
  const dispatch = useDispatch();
  const [started, setStarted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [answers, setAnswers] = useState({});
  const params = useParams();
  const {
    fetchQuestionsLoading,
    fetchQuestionsError,
    submitAnswerLoading,
    submitanswerError,
    questions,
    category,
    point,
  } = useSelector((state) => state.quiz);

  const fetch = () => {
    dispatch(
      questionsFetchStart({
        categoryId: params.id,
      })
    );
  };

  useEffect(() => {
    fetch();
  }, [params.id]);

  useEffect(() => {
    return () => answerSubmitClear();
  }, []);

  const handleChoiceChange = (question, answer) => {
    setAnswers((prev) => ({
      ...prev,
      [question]: answer,
    }));
  };

  const handleSubmit = () => {
    let answer = {};
    questions.forEach((question) => {
      answer[question._id] = answers[question._id] || null;
    });
    setSubmitted(true);
    dispatch(answerSubmitStart({ data: answer }));
  };

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div>
          <h4 className="text-red-600 font-montserrat-medium">
            You're time is up
          </h4>
        </div>
      );
    }

    return (
      <div className="flex flex-row gap-2 text-primary font-montserrat-bold">
        <span>{hours}</span>
        <span>:</span>
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </div>
    );
  };

  if (fetchQuestionsError) {
    return (
      <Error
        className="h-96"
        message={fetchQuestionsError.message}
        onRetry={fetch}
      />
    );
  }
  if (fetchQuestionsLoading || !questions) {
    return <Loader className="h-96" title="Loading questions ..." />;
  }

  if (!started) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <h2 className="font-montserrat-bold text-3xl mb-2">
          {category.name.en}
        </h2>
        {questions.length > 0 ? (
          <>
            <p className="text-gray-600 font-montserrat-medium mb-4">
              {questions.length} Questions,{" "}
              {category.timePerQuestion * questions.length} seconds given
            </p>
            <button
              className="text-white bg-black px-6 py-2 rounded-full font-montserrat-medium hover:bg-opacity-80"
              onClick={() => setStarted(true)}
            >
              Start
            </button>
          </>
        ) : (
          <>
            <p className="mb-4 text-center font-montserrat-medium text-red-500  ">
              You have taken all questions
            </p>
            <Link
              to="/quizzes"
              className="text-white bg-black px-6 py-2 rounded-full font-montserrat-medium hover:bg-opacity-80"
            >
              Go Back
            </Link>
          </>
        )}
      </div>
    );
  }

  if (submitted) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center">
        {submitanswerError ? (
          <Error
            className="h-96"
            message={submitanswerError.message}
            onRetry={fetch}
          />
        ) : submitAnswerLoading ? (
          <Loader className="" title="Submitting answer ..." />
        ) : (
          <>
            <p className="text-gray-600 font-montserrat-medium mb-4">
              You got {point} point
            </p>

            <Link
              to="/quizzes"
              className="text-white bg-black px-6 py-2 rounded-full font-montserrat-medium hover:bg-opacity-80 mb-4"
              onClick={() => setStarted(true)}
            >
              Go Back
            </Link>

            <Link
              to="/leader-board"
              className="text-white bg-yellow-400 px-6 py-2 rounded-full font-montserrat-medium hover:bg-opacity-80"
              onClick={() => setStarted(true)}
            >
              Go to leader board
            </Link>
          </>
        )}
      </div>
    );
  }
  return (
    <div className="p-4 py-8 ">
      <div className="flex justify-center mb-4">
        <Countdown
          date={Date.now() + category.timePerQuestion * questions.length * 1000}
          renderer={countdownRenderer}
          onComplete={handleSubmit}
        />
      </div>

      <section className="flex flex-col gap-4 flex-grow">
        {questions.map((item, index) => (
          <article key={item._id}>
            <h3 className="text-lg font-montserrat-medium">
              <span>{index + 1}.</span> {item.title.en}
            </h3>
            <ol className="mt-1 ml-6 flex flex-col gap-2">
              {item.choices.map((choice, index) => (
                <li
                  key={`${item._id}-${index}`}
                  className="flex items-center gap-2"
                >
                  <input
                    type="radio"
                    name={`${item._id}`}
                    className="text-primary focus:outline-none  focus:ring-0"
                    id={`${item._id}-${index}`}
                    onChange={() => handleChoiceChange(item._id, index)}
                  />
                  <label
                    htmlFor={`${item._id}-${index}`}
                    className="font-montserrat-light"
                  >
                    {choice.en}
                  </label>
                </li>
              ))}
            </ol>
          </article>
        ))}
      </section>
      <div className="flex justify-center mt-8">
        <button
          className="text-white bg-black px-6 py-2 rounded-full font-montserrat-medium hover:bg-opacity-80"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default QuizStart;

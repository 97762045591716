import { all, call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";

import {
  feedsFetchStart,
  feedsFetchSuccess,
  feedsFetchError,
  feedFetchStart,
  feedFetchSuccess,
  feedFetchError,
} from "./reducer";
import { structureError } from "../../utils/error-factory";

function* fetchFeedsAsync({ payload }) {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { feeds, page, limit, totalDocs },
      },
    } = yield axios.get(`/feeds`, {
      params: {
        _page: payload.page,
        _limit: payload.limit,
        published: true,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(
      feedsFetchSuccess({
        feeds,
        page,
        limit,
        total: totalDocs,
        loadMore: payload.loadMore,
      })
    );
  } catch (error) {
    yield put(
      feedsFetchError({
        error: structureError(error),
        loadMore: payload.loadMore,
      })
    );
  }
}

function* fetchFeedAsync({ payload }) {
  const { token } = yield select((state) => state.auth);
  try {
    const {
      data: {
        data: { feed },
      },
    } = yield axios.get(`/feeds/${payload.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(
      feedFetchSuccess({
        feed,
      })
    );
  } catch (error) {
    yield put(
      feedFetchError({
        error: structureError(error),
      })
    );
  }
}

function* onFetchFeeds() {
  yield takeLatest(feedsFetchStart.type, fetchFeedsAsync);
}

function* onFetchFeed() {
  yield takeLatest(feedFetchStart.type, fetchFeedAsync);
}

function* feedSagas() {
  yield all([call(onFetchFeeds), call(onFetchFeed)]);
}

export default feedSagas;

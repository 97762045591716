import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import {
  competitionsFetchStart,
  competitionsFetchSuccess,
  competitionsFetchError,
  liveScoresFetchStart,
  liveScoresFetchSuccess,
  liveScoresFetchError,
  standingsFetchStart,
  standingsFetchSuccess,
  standingsFetchError,
  historiesFetchStart,
  historiesFetchSuccess,
  historiesFetchError,
} from "./reducer";
import { structureError } from "../../utils/error-factory";

function* fetchCompetitionsAsync() {
  try {
    const {
      data: {
        data: { competitions },
      },
    } = yield axios.get(`/competitions`);
    yield put(
      competitionsFetchSuccess({
        competitions,
      })
    );
  } catch (error) {
    yield put(
      competitionsFetchError({
        error: structureError(error),
      })
    );
  }
}

function* fetchLiveScoreAsync({ payload }) {
  try {
    const {
      data: {
        data: { liveScore },
      },
    } = yield axios.get(`/competitions/${payload.id}/live-score`);
    yield put(
      liveScoresFetchSuccess({
        liveScores: liveScore,
      })
    );
  } catch (error) {
    yield put(
      liveScoresFetchError({
        error: structureError(error),
      })
    );
  }
}

function* fetchStandingsAsync({ payload }) {
  try {
    const {
      data: {
        data: { standing },
      },
    } = yield axios.get(`/competitions/${payload.id}/standing`);
    yield put(
      standingsFetchSuccess({
        standings: standing,
      })
    );
  } catch (error) {
    yield put(
      standingsFetchError({
        error: structureError(error),
      })
    );
  }
}

function* fetchHistoriesAsync({ payload }) {
  try {
    const {
      data: {
        data: { history, total },
      },
    } = yield axios.get(`/competitions/${payload.id}/history`, {
      params: {
        page: payload.page,
      },
    });
    yield put(
      historiesFetchSuccess({
        histories: history,
        page: payload.page,
        total,
        loadMore: payload.loadMore,
      })
    );
  } catch (error) {
    yield put(
      historiesFetchError({
        error: structureError(error),
      })
    );
  }
}

function* onFetchCompetitions() {
  yield takeLatest(competitionsFetchStart.type, fetchCompetitionsAsync);
}

function* onFetchLiveScores() {
  yield takeLatest(liveScoresFetchStart.type, fetchLiveScoreAsync);
}

function* onFetchStandings() {
  yield takeLatest(standingsFetchStart.type, fetchStandingsAsync);
}

function* onFetchHistories() {
  yield takeLatest(historiesFetchStart.type, fetchHistoriesAsync);
}

function* competitionSagas() {
  yield all([
    call(onFetchCompetitions),
    call(onFetchLiveScores),
    call(onFetchStandings),
    call(onFetchHistories),
  ]);
}

export default competitionSagas;

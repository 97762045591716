import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserCircleIcon, StarIcon } from "@heroicons/react/solid";

import { logOut } from "../../store/auth/reducer";

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="p-4 flex flex-col items-center">
      <div className=" flex flex-col items-center shadow-md w-full rounded-sm mb-8 py-4">
        <UserCircleIcon className="w-24 text-gray-400" />
        <h4 className="my-2 font-montserrat-bold text-gray-600 text-xl">
          {user.firstName} {user.lastName}
        </h4>
        <h6 className="font-montserrat-light text-gray-600 ">
          +{user.phoneNumber}
        </h6>
      </div>

      <div className="flex items-center bg-green-100 w-full p-3 rounded-md mb-4">
        <StarIcon className="w-8 mr-4 text-green-600" />
        <h5 className="font-montserrat-medium text-gray-800">
          Your Point: {user.point}
        </h5>
      </div>
      <Link
        to="/leader-board"
        className="flex items-center bg-white w-full p-3 rounded-md mb-4 cursor-pointer hover:bg-yellow-100"
      >
        <StarIcon className="w-8 mr-4 text-yellow-600" />
        <h5 className="font-montserrat-medium text-gray-800">Leader Board</h5>
      </Link>

      <button
        onClick={() => dispatch(logOut())}
        className="bg-red-600 w-full p-3 rounded-md text-white font-montserrat-bold  hover:bg-red-400"
      >
        Logout
      </button>
    </div>
  );
};

export default Profile;
